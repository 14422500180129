<template>
  <div class="add">
    <template v-if="$route.query.type==1">
      <breadcrumb :breadcrumbList="addBreadcrumbList1"></breadcrumb>
    </template>
    <template v-if="$route.query.type==2">
      <breadcrumb :breadcrumbList="addBreadcrumbList2"></breadcrumb>
    </template>
    <template v-if="$route.query.type==4||$route.query.type==3">
      <breadcrumb :breadcrumbList="addBreadcrumbList4"></breadcrumb>
    </template>

    <div class="main-content">
      <baseForm v-if="$route.query.type==1||$route.query.type==2"></baseForm>
      <baseFormShop v-if="$route.query.type==4||$route.query.type==3"></baseFormShop>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import {addBreadcrumbList1,addBreadcrumbList2,addBreadcrumbList4 } from '../config'
import baseForm from '../base-ui/base-form/base-form.vue'
import baseFormShop from '../base-ui/base-form/base-form-shop.vue'
export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    baseForm,
    baseFormShop
  },
  setup() {


    return {
      addBreadcrumbList1,
      addBreadcrumbList2,
      addBreadcrumbList4
    }

  }
})
</script>

<style scoped lang="less">
.add {
  .main-content {
    min-width: 1080px;
    padding: 20px;
    margin: 20px;
    background-color: #fff;
  }
}
</style>




